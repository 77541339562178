<template>
  <colaboration-solutions />
</template>

<script>
import ColaborationSolutions from "../../components/solutions/networking/ColaborationSolutions.vue";
export default {
  name: "collaboration-page",
  components: { ColaborationSolutions },
};
</script>
