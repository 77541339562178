<template>
  <div class="ultimate text-center mt-5 pt-5">
    <h2 class="title-yellow h1">Ultimate Benefits of Collaboration</h2>
    <p class="mt-5">
      If a company wants to deliver the full potency of video, it must trust its
      telepresence and video collaboration solutions. The scheme organization is
      using must offer satisfying audio and video quality with less distortion
      in connectivity and must be easy to use. Video collaboration solutions
      provide real-time communication and excellent cooperation with colleagues
      and clients, which leaves a substantial impact on business.
    </p>
  </div>
</template>

<script>
export default {
  name: "eleventh-collab-section",
};
</script>
