<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="title-blue h1">Modifying the Ways of Doing Work</h2>
    <p class="mt-5">
      Are you still working with old thumb methods? Do you want to improve your
      ways of working? If yes, then our collaboration is the best way to do it.
      Harvard business reviews surveyed the concept that the means of
      collaboration are changing within the organization. Business leaders from
      all over the world took part in that survey and talked about it.
    </p>
  </div>
</template>

<script>
export default {
  name: "second-collab-section",
};
</script>
