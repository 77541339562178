<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Role Of Collaboration In A Business</h2>
      <p class="desc">
        In any business, collaboration and cooperation play a vital role. This
        defines how the partners are working together and serving their clients.
        At EJAF, our main agenda is to deliver you a UC and collaboration so
        that your business can have an enormous and strong impact. We will help
        in the following ways.
      </p>
      <h3>Increase workers input and obtain a high output</h3>
      <ul>
        <li>
          <p>
            We bring ease to our clients by eradicating inefficient and poor
            communication.
          </p>
        </li>
      </ul>
      <h3>Enabling Successful Communication</h3>
      <ul>
        <li>
          Valimail makes it easier for you to communicate by enriching your
          conversations with media tools like video and audio.
        </li>
        <li>
          Valimail brings convenience to workplaces by enabling instant chat and
          allows organizations to work better in an enhanced virtual
          environment.
        </li>
      </ul>
      <h3>Allows significant potency</h3>
      <ul>
        <li>
          <p>
            Valimail enables you to communicate with your clients and employees
            from any remote location, on any device.
          </p>
        </li>
      </ul>
      <h3>Clarifying a communication policy</h3>
      <ul>
        <li>
          <p>
            Valimail simplifies consolidation by enabling centralized, managed,
            and cloud deployments. Removes the silo mentality at the workplace.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/9-role-of-collaboration-in-a-business.png"
        alt="Role Of Collaboration In A Business"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "tenth-collab-section",
};
</script>
