<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Why Customer Collaboration is Beneficial?</h2>
      <p class="desc">
        It helps the firms and companies so that they can give a better and
        connected digital experience. You can provide logical, consistent and
        high capacity ventures for your clients. Adaptation to internal failure
        guarantees continuous activity and far-reaching detailing gives business
        insights to upgrade your contact place.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/3-customer-collab.png"
        alt="Why Customer Collaboration is Beneficial?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fourth-collab-section",
};
</script>
