<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Collaboration Solutions</h2>
      <p class="desc">
        By applying Collaboration technologies, different collaboration
        solutions are obtained, which proved to be very beneficial.
        Collaboration technologies have the following uses. It helps in the
        sharing of information. Through these technologies, you can also make
        your business a successful one as it enhances the decision-making power
        and increases the ability to respond to different situations. To
        motivate the people and have it also combines the customs and traditions
        with communication solutions. To motivate the people and acquire high
        output, it combines the customs and traditions with communication
        solutions. Our customers have faith in us, that’s why our clients trust
        us concerning the collaboration solution. We provide collaboration
        technologies that make it easier for them to share information and have
        outstanding experiences.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/1-collaboration-solutions.png"
        alt="Collaboration Solutions"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-collab-section",
};
</script>
