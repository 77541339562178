<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Meeting and Seminars</h2>
      <p class="desc">
        Now give more secure cooperation from all the electronic gadgets,
        including PCs, mobile phones and seminar halls. Your increase in
        collaboration and coordination with workers, clients and partners can
        coordinate and cooperate from any part of the world with our upgradable
        video, voice and content sharing.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/6-meeting-and-seminars.png"
        alt="Meeting and Seminars"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "seventh-collab-section",
};
</script>
