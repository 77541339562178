<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Brought Together Communications</h2>
      <p class="desc">
        You can quickly unite individuals whenever, anyplace and on any gadget
        with our integrated joint effort framework for voice and video calling,
        informing and versatility.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/5-brought-together-communications.png"
        alt="Brought Together Communications"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "sixth-collab-section",
};
</script>
