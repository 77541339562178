<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Advantages of Collaboration</h2>
      <p class="desc">
        Enjoy user-friendly, connective and blue-ribbon collaboration
        experience. Everything you will ever need for the group collaboration
        will be provided. Gadgets, as per your wish and convenience will be
        provided so that you can also contact and link your virtual teams even
        outside the room. Some new techniques are there, which make all your
        meeting productive and smarter by removing all the difficulties one has
        to face while using means of video.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/4-advantages-of-collaboration.png"
        alt="Advantages of Collaboration"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fiveth-collab-section",
};
</script>
