<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Why Collaboration Is Important?</h2>
      <p class="desc">
        Collaboration solutions help in offering effective and powerful ways to
        create a fruitful, constructive, cooperating and essential link between
        the teams utilizing the overall ability of individuals. The incredible
        thing about these collaboration solutions is that they are not limited
        to boardrooms and executive officers, but can be used by anyone.
        Audio(telephones) and video collaborating solutions are provided on
        every electronic gadget, including PC, mobile and team rooms. This makes
        it easy for groups to cooperate and solve their problems. Moreover, you
        can easily convert your business ideas from a consumer focus to a supply
        management network to teaching.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/2-why-collaboration.png"
        alt="Why Collaboration Is Important?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "third-collab-section",
};
</script>
