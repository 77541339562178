<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">
        Creates A Real and Natural Connection with Colleagues
      </h2>
      <p class="desc">
        Now you can easily convert your long-term meetings into active sessions.
        The presence of steel creates a secure, innate and influential
        connection between humans that is a constructive effect as sitting in
        the same room.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/8-creates-a-real-and-natural-connection-with-colleagues.png"
        alt="Creates A Real and Natural Connection with Colleagues"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "nineth-collab-section",
};
</script>
