<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Virtual Office</h2>
      <p class="desc">
        Lined up meetings are now the trend. Now, you can have a virtual office
        outside the physical office. You can now even do meeting sessions with
        the same enthusiasm and coordination on mobile phones with an innovation
        love of content and a secured system that doesn’t rely on your location.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/collaboration-solutions/7-virtual-office.png"
        alt="Virtual Office"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "eightth-collab-section",
};
</script>
